import React, { useState, useLayoutEffect } from 'react';
import moment from 'moment';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

import SEO from '../components/seo';
import Layout from '../components/layout';
import Flex from '../components/styled/base/Flex';
import Box from '../components/styled/base/Box';
import Content from '../components/styled/Content';
import Typography from '../components/styled/base/Typography';
import Icon from '../components/styled/Icon';
import { StyledAnchor } from '../components/styled/base/A';
import List from '../components/styled/base/List';

const Media = styled(Box)`
  & img {
    object-fit: cover;
    height: 400px;
    width: 100%;
  }

  & video {
    margin: 16px 0;
  }
`;

const Item = styled(Flex)`
  &.active {
    color: ${({ theme }) => theme.colors.secondary};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.secondaryLight};
  }
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  align-items: center;
`;

const ListItem = ({ post, posts, index, isOpen = true }) => (
  <Item
    style={{ display: isOpen ? 'flex' : 'none' }}
    activeClassName="active"
    partiallyActive={true}
    as={StyledAnchor}
    to={`/posts/${post.slug}`}
    color="primary"
    fontSize={3}
    fontWeight="semibold"
    height={['60px', null, null, '44px']}
    bg="background"
    mb={
      index === posts.length - 1
        ? ['-43px', null, null, '-16px']
        : ['10px', null, null, '25px']
    }
    pl={['15px', null, null, '22px']}
    pr="15px"
    justifyContent={['space-between', null, null, 'flex-start']}
  >
    <Icon name="chevron_left" size="20px" mr="30px" />
    <Typography display="inline-block" margin="0px">
      {post.title}
    </Typography>
  </Item>
);

const Post = ({ data }) => {
  const [open, toggleOpen] = useState(false);

  useLayoutEffect(() => {
    function updateWidth() {
      if (window.innerWidth > 991) {
        toggleOpen(true);
      }
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  return (
    <Layout>
      <SEO title={data.post.title} />
      <Flex
        position="sticky"
        top="92px"
        flexDirection={['column', null, null, 'row-reverse']}
        justifyContent="space-between"
        mb="5"
      >
        <Box width={[1, null, null, 1 / 3]} mt="-20px" mb="25px">
          <Box
            bg={['transparent', null, null, 'secondaryOpaque']}
            pt="40px"
            pb={['40px', null, null, 0]}
            pr={[0, null, null, '53px', '70px']}
            pl={[0, null, null, '0']}
            float={[null, null, null, 'right']}
            position="sticky"
            top="77px"
          >
            <List as="nav" p="0" ml={['0', null, null, '-50px']}>
              <Flex
                display={['block', null, null, 'none']}
                width="100%"
                color="background"
                bg="secondary"
                fontSize={3}
                fontWeight="semibold"
                height={['60px', null, null, '44px']}
                boxShadow={1}
                borderRadius="6px"
                mb="10px"
                px="15px"
                justifyContent={['space-between', null, null, 'flex-end']}
                alignItems="center"
                onClick={() => toggleOpen(!open)}
              >
                <Typography display="inline-block" margin="0px">
                  {open ? 'Close' : 'Open'} Menu
                </Typography>
                <Icon name={open ? 'close' : 'menu'} size="18px" ml="30px" />
              </Flex>
              {data.posts.edges.map(({ node: post }, index) => (
                <ListItem
                  key={index}
                  isOpen={open}
                  post={post}
                  index={index}
                  posts={data.posts.edges}
                />
              ))}
            </List>
          </Box>
        </Box>
        <Flex width={[1, null, null, '60%']} flexDirection="column">
          {data.post.image &&
            (data.post.image.fluid ? (
              <Media
                width="full"
                py="3"
                textAlign={['center', null, null, 'left']}
              >
                <img
                  src={data.post.image.fluid.src}
                  alt={data.post.image.title}
                />
              </Media>
            ) : (
              <Media>
                <video controls width="100%" height="400px">
                  <source src={data.post.image.file.url} />
                  <track kind="captions" src="" srcLang="en" />
                  Sorry, je browser ondersteunt geen embedded videos.
                </video>
              </Media>
            ))}
          <Typography variant="h2" as="h2" mb={1}>
            {data.post.title}
          </Typography>
          <Typography
            variant="p"
            as="p"
            color="gray"
            mt="0"
            fontSize="14px !important"
          >
            {moment(data.post.createdAt).format('D MMMM YYYY')}
          </Typography>
          <Content
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(data.post.content.json),
            }}
          />
        </Flex>
      </Flex>
    </Layout>
  );
};

Post.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
};

export default Post;

export const postQuery = graphql`
  query($id: String!) {
    post: contentfulPost(id: { eq: $id }) {
      id
      slug
      title
      image {
        title
        fluid(cropFocus: CENTER, quality: 100, maxHeight: 800, maxWidth: 600) {
          src
        }
        file {
          url
        }
      }
      content {
        json
      }
      createdAt
    }
    posts: allContentfulPost(
      sort: { fields: createdAt, order: DESC }
      limit: 6
    ) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`;
